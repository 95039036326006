import { HTTPError } from 'ky';
import _ from 'lodash';
import { DateTime } from 'luxon';

import { Alert, AlertType } from '../model/alert';
import { AppPage, UserAction } from '../model/analytics';
import { BuysidePortfolioAttribution, BuysidePortfolioTimeFrames } from '../model/buysideportfolio';
import {
  BuysideIdeaReturnDataMap,
  Company,
  CompanyDetails,
  CompanyEnfusionPriceDetails,
  CompanyOverviewMap,
  CompanyPriceTargetInput,
  CompanyStateChangeMap,
  CompanyStateComplete,
  UpdateCompanyStateInput,
} from '../model/company';
import { AmendIdeaInput, CloseIdeaInput, Idea, OpenIdeaInput } from '../model/idea';
import { LeagueSnapshot, LeagueTimeHorizon } from '../model/league';
import { Portfolio, PortfolioEnfusionPriceDetails } from '../model/portfolio';
import {
  AmendPositionInput,
  ClosedPosition,
  OpenPositionInput,
  Position,
  PositionLeg,
  RemovePositionInput,
} from '../model/position';
import {
  AnalystTheme,
  AnalystThemeDetails,
  AnalystThemeInput,
  AnalystThemeLeg,
  AnalystThemeStatus,
  ParentTheme,
  ParentThemeDetails,
  ParentThemeInput,
  ParentThemeLeg,
  ThemeCompanyActiveChangeTransition,
  ThemeCompanyState,
  ThemeCompanyStateTransition,
} from '../model/theme';
import httpClient from './http';
import { LegalEventType } from './legal-event';
import { parseDateTime, parseDuration, RawDateTimes } from './parse';

const Api = {
  addToCoverage: async (companyId: string) => {
    return httpClient.post('/api/coverage', {
      json: {
        companyId,
      },
    });
  },

  getCompanyOverview: async (): Promise<CompanyOverviewMap> => {
    const response: RawDateTimes<CompanyOverviewMap> = await httpClient.get(`/api/companies/overview`).json();

    return _.mapValues(response, (r) => {
      return {
        ...r,
        priceTarget:
          r.priceTarget === null
            ? null
            : {
                ...r.priceTarget,
                time: parseDateTime(r.priceTarget.time),
                duration: r.priceTarget.duration ? parseDuration(r.priceTarget.duration) : null,
                targetDate: r.priceTarget.targetDate ? parseDateTime(r.priceTarget.targetDate) : null,
              },
      };
    });
  },

  getAllIdeaReturnDataForUser: async (): Promise<BuysideIdeaReturnDataMap> => {
    const response: RawDateTimes<BuysideIdeaReturnDataMap> = await httpClient.get(`/api/idea-returns`).json();
    const result: BuysideIdeaReturnDataMap = {};
    Object.keys(response).forEach((ideaId) => {
      const data = response[ideaId];
      result[ideaId] = {
        ...data,
        ideaOpenTime: parseDateTime(data.ideaOpenTime),
      };
    });
    return result;
  },

  getCompanyDetails: async (companyId: string): Promise<CompanyDetails> => {
    const response: RawDateTimes<CompanyDetails> = await httpClient.get(`/api/companies/${companyId}/details`).json();
    return {
      ...response,
      stateHistory: _.map(response.stateHistory, (t) => ({
        ...t,
        transitionTime: parseDateTime(t.transitionTime),
      })),
      ideaHistories: _.map(response.ideaHistories, (h) => ({
        ...h,
        actions: _.map(h.actions, (a) => ({
          ...a,
          time: parseDateTime(a.time),
        })),
      })),
      positionHistories: _.map(response.positionHistories, (h) => ({
        ...h,
        actions: _.map(h.actions, (a) => ({
          ...a,
          time: parseDateTime(a.time),
        })),
      })),
      latestPriceTarget:
        response.latestPriceTarget === null
          ? null
          : {
              ...response.latestPriceTarget,
              time: parseDateTime(response.latestPriceTarget.time),
              duration: response.latestPriceTarget.duration ? parseDuration(response.latestPriceTarget.duration) : null,
              targetDate: response.latestPriceTarget.targetDate
                ? parseDateTime(response.latestPriceTarget.targetDate)
                : null,
            },
      priceTargetHistory: _.map(response.priceTargetHistory, (p) =>
        p.baseCase === null
          ? {
              ...p,
              time: parseDateTime(p.time),
            }
          : {
              ...p,
              time: parseDateTime(p.time),
              duration: p.duration ? parseDuration(p.duration) : null,
              targetDate: p.targetDate ? parseDateTime(p.targetDate) : null,
            },
      ),
    };
  },

  getCompanyData: async (companyId: string): Promise<Company> => {
    const response: RawDateTimes<CompanyDetails> = await httpClient.get(`/api/companies/${companyId}/details`).json();

    return response.company;
  },

  getCompanyStates: async (): Promise<CompanyStateComplete[]> => {
    return httpClient.get('/api/companyStates').json();
  },

  updateCompanyState: async (companyId: string, input: UpdateCompanyStateInput): Promise<CompanyStateChangeMap> => {
    return httpClient
      .put(`/api/companies/${companyId}/state`, {
        json: input,
      })
      .json();
  },

  applyCompanyStateChanges: async (stateChanges: CompanyStateChangeMap) => {
    return httpClient.put('/api/companies/state-changes', {
      json: stateChanges,
    });
  },

  cancelCompanyStateChanges: async (stateChanges: CompanyStateChangeMap) => {
    return httpClient.put('/api/companies/cancel-state-changes', {
      json: stateChanges,
    });
  },

  setCompanyPriceTarget: async (companyId: string, priceTarget: CompanyPriceTargetInput) => {
    return httpClient.put(`/api/companies/${companyId}/price-target`, {
      json: priceTarget,
    });
  },

  deleteCompanyPriceTarget: async (companyId: string) => {
    return httpClient.delete(`/api/companies/${companyId}/price-target`);
  },

  searchForCompany: async (query: string): Promise<Company[]> => {
    return httpClient
      .get(`/api/companies/search`, {
        searchParams: {
          query,
        },
      })
      .json();
  },

  getOpenIdeas: async (): Promise<Idea[]> => {
    const ideas = await httpClient.get('/api/ideas').json<RawDateTimes<Idea>[]>();
    return _(ideas)
      .map((idea) => ({
        ...idea,
        time: parseDateTime(idea.time),
      }))
      .value();
  },

  openIdea: async (input: OpenIdeaInput): Promise<string> => {
    return httpClient.post('/api/ideas', { json: input }).json();
  },

  amendIdea: async (ideaId: string, input: AmendIdeaInput): Promise<string> => {
    return httpClient.put(`/api/ideas/${ideaId}`, { json: input }).json();
  },

  closeIdea: async (ideaId: string, input: CloseIdeaInput): Promise<string> => {
    return httpClient.delete(`/api/ideas/${ideaId}`, { json: input }).json();
  },

  getParentThemes: async (): Promise<ParentTheme[]> => {
    const parentThemes = await httpClient.get('/api/themes/parent-themes').json<RawDateTimes<ParentTheme>[]>();
    return _(parentThemes)
      .map((parentTheme) => ({
        ...parentTheme,
        time: parseDateTime(parentTheme.time),
      }))
      .value();
  },

  getParentThemeLegs: async (): Promise<ParentThemeLeg[]> => {
    const parentThemeLegs = await httpClient
      .get('/api/themes/parent-theme-legs')
      .json<RawDateTimes<ParentThemeLeg>[]>();
    return _(parentThemeLegs)
      .map((parentThemeLeg) => ({
        ...parentThemeLeg,
        time: parseDateTime(parentThemeLeg.time),
      }))
      .value();
  },

  createParentTheme: async (input: ParentThemeInput): Promise<string> =>
    httpClient.post('/api/themes/parent-themes', { json: input }).json(),

  updateParentTheme: async (parentThemeId: string, input: ParentThemeInput): Promise<string> =>
    httpClient.put(`/api/themes/parent-themes/${parentThemeId}`, { json: input }).json(),

  createAnalystTheme: async (input: AnalystThemeInput): Promise<string> =>
    httpClient.post('/api/themes/analyst-themes', { json: input }).json(),

  updateAnalystTheme: async (analystThemeId: string, input: AnalystThemeInput): Promise<string> =>
    httpClient.put(`/api/themes/analyst-themes/${analystThemeId}`, { json: input }).json(),

  removeCompanyFromAnalystTheme: async (companyId: string, analystThemeId: string): Promise<Response> =>
    httpClient.delete(`/api/themes/analyst-themes/${analystThemeId}/companies/${companyId}`),

  getAnalystThemes: async (): Promise<AnalystTheme[]> => {
    const analystThemes = await httpClient.get('/api/themes/analyst-themes').json<RawDateTimes<AnalystTheme>[]>();
    return _(analystThemes)
      .map((analystTheme) => ({
        ...analystTheme,
        startDate: analystTheme.startDate !== null ? parseDateTime(analystTheme.startDate) : null,
        time: parseDateTime(analystTheme.time),
        createdTime: parseDateTime(analystTheme.createdTime),
      }))
      .value();
  },

  getAnalystThemeLegs: async (): Promise<AnalystThemeLeg[]> => {
    const analystThemeLegs = await httpClient
      .get('/api/themes/analyst-theme-legs')
      .json<RawDateTimes<AnalystThemeLeg>[]>();
    return _(analystThemeLegs)
      .map((analystThemeLeg) => ({
        ...analystThemeLeg,
        startDate: analystThemeLeg.startDate !== null ? parseDateTime(analystThemeLeg.startDate) : null,
        time: parseDateTime(analystThemeLeg.time),
      }))
      .value();
  },

  archiveTheme: async (analystThemeId: string, status: Exclude<AnalystThemeStatus, AnalystThemeStatus.DELETED>) => {
    return httpClient.patch(`/api/themes/analyst-themes/${analystThemeId}`, { json: { status } });
  },

  deleteTheme: async (analystThemeId: string) => {
    return httpClient.delete(`/api/themes/analyst-themes/${analystThemeId}`);
  },

  changeThemeStatus: async (
    companyId: string,
    analystThemeId: string,
    active: boolean,
  ): Promise<ThemeCompanyActiveChangeTransition> => {
    const themeCompanyStateTransition = await httpClient
      .patch(`/api/themes/analyst-themes/${analystThemeId}/companies/${companyId}`, {
        json: { active },
      })
      .json<RawDateTimes<ThemeCompanyActiveChangeTransition>>();

    return {
      ...themeCompanyStateTransition,
      time: parseDateTime(themeCompanyStateTransition.time),
    };
  },

  getThemeCompanyStates: async (): Promise<ThemeCompanyState[]> => {
    const themeCompanyStates = await httpClient
      .get('/api/themes/theme-company-states')
      .json<RawDateTimes<ThemeCompanyState>[]>();
    return _(themeCompanyStates)
      .map((themeCompanyState) => ({
        ...themeCompanyState,
        time: parseDateTime(themeCompanyState.time),
        createdTime: parseDateTime(themeCompanyState.createdTime),
      }))
      .value();
  },

  getThemeCompanyStateTransitions: async (): Promise<ThemeCompanyStateTransition[]> => {
    const themeCompanyStateTransitions = await httpClient
      .get('/api/themes/theme-company-state-transitions')
      .json<RawDateTimes<ThemeCompanyStateTransition>[]>();
    return _(themeCompanyStateTransitions)
      .map((themeCompanyStateTransition) => ({
        ...themeCompanyStateTransition,
        time: parseDateTime(themeCompanyStateTransition.time),
      }))
      .value();
  },

  getThemeCompanies: async (): Promise<Company[]> => {
    return httpClient.get('/api/themes/theme-companies').json();
  },

  getParentThemeDetails: async (parentThemeId: string): Promise<ParentThemeDetails> => {
    const parentThemeDetails: RawDateTimes<ParentThemeDetails> = await httpClient
      .get(`/api/themes/parent-themes/${parentThemeId}/details`)
      .json();

    return {
      ...parentThemeDetails,
      createdTime: parseDateTime(parentThemeDetails.createdTime),
      parentTheme: { ...parentThemeDetails.parentTheme, time: parseDateTime(parentThemeDetails.parentTheme.time) },
    };
  },

  getAnalystThemesForParentTheme: async (parentThemeId: string): Promise<AnalystTheme[]> => {
    const analystThemes: RawDateTimes<AnalystTheme>[] = await httpClient
      .get(`/api/themes/parent-themes/${parentThemeId}/analyst-themes`)
      .json();
    return _(analystThemes)
      .map((theme) => ({
        ...theme,
        startDate: theme.startDate !== null ? parseDateTime(theme.startDate) : null,
        time: parseDateTime(theme.time),
        createdTime: parseDateTime(theme.createdTime),
      }))
      .value();
  },

  getAnalystThemeDetails: async (analystThemeId: string | undefined): Promise<AnalystThemeDetails> => {
    const analystThemeDetails: RawDateTimes<AnalystThemeDetails> | HTTPError = await httpClient
      .extend({
        throwHttpErrors: false,
      })
      .get(`/api/themes/analyst-themes/${analystThemeId ?? 'undefined'}/details`)
      .json();

    function isHTTPError(response: RawDateTimes<AnalystThemeDetails> | HTTPError): response is HTTPError {
      return !('analystTheme' in response);
    }

    if (isHTTPError(analystThemeDetails)) {
      if (analystThemeDetails.response.status === 404) {
        throw new Error('Theme not found');
      } else {
        throw new Error('Unexpected error');
      }
    }

    return {
      ...analystThemeDetails,
      analystTheme: {
        ...analystThemeDetails.analystTheme,
        startDate:
          analystThemeDetails.analystTheme.startDate !== null
            ? parseDateTime(analystThemeDetails.analystTheme.startDate)
            : null,
        time: parseDateTime(analystThemeDetails.analystTheme.time),
        createdTime: parseDateTime(analystThemeDetails.analystTheme.createdTime),
      },
    };
  },

  getCompaniesForAnalystTheme: async (analystThemeId: string | undefined): Promise<Company[]> => {
    return await httpClient.get(`/api/themes/analyst-themes/${analystThemeId ?? 'undefined'}/companies`).json();
  },

  searchForTheme: async (query: string): Promise<(AnalystTheme | ParentTheme)[]> => {
    return await httpClient
      .get(`/api/themes/search`, {
        searchParams: {
          query,
        },
      })
      .json();
  },

  isTosAccepted: async (): Promise<boolean> => {
    try {
      return await httpClient.get(`/api/event/legal/tos-acceptance`).json<boolean>();
    } catch (e) {
      return false;
    }
  },

  logLegalEvent: async (type: LegalEventType): Promise<Response> =>
    httpClient.post('/api/event/legal', {
      searchParams: {
        type,
      },
    }),

  getPositionByCompanyId: async (companyId: string): Promise<Position> => {
    const position = await httpClient.get(`api/positions/active/company/${companyId}`).json<RawDateTimes<Position>>();
    return {
      ...position,
      time: parseDateTime(position.time),
    };
  },

  getPositions: async (): Promise<Position[]> => {
    const positions = await httpClient.get('/api/positions').json<RawDateTimes<Position>[]>();
    return _(positions)
      .map((position) => ({
        ...position,
        time: parseDateTime(position.time),
      }))
      .value();
  },

  getPositionsLegs: async (): Promise<PositionLeg[]> => {
    const positions = await httpClient.get('/api/positions/position-legs').json<RawDateTimes<PositionLeg>[]>();
    return _(positions)
      .map((position) => ({
        ...position,
        time: parseDateTime(position.time),
      }))
      .value();
  },

  getClosedPositions: async (): Promise<ClosedPosition[]> => {
    const positions = await httpClient.get('/api/positions/removed').json<RawDateTimes<ClosedPosition>[]>();
    return _(positions)
      .map((position) => ({
        ...position,
        time: parseDateTime(position.time),
        priceTargetAtClose: position.priceTargetAtClose
          ? {
              ...position.priceTargetAtClose,
              time: parseDateTime(position.priceTargetAtClose.time),
              duration: position.priceTargetAtClose.duration
                ? parseDuration(position.priceTargetAtClose.duration)
                : null,
              targetDate: position.priceTargetAtClose.targetDate
                ? parseDateTime(position.priceTargetAtClose.targetDate)
                : null,
            }
          : null,
      }))
      .value();
  },

  openPosition: async (input: OpenPositionInput): Promise<string> => {
    return httpClient.post('/api/positions', { json: input }).json();
  },

  amendPosition: async (positionId: string, input: AmendPositionInput): Promise<string> => {
    return httpClient.put(`/api/positions/${positionId}`, { json: input }).json();
  },

  removePosition: async (positionId: string, input: RemovePositionInput): Promise<string> => {
    return httpClient.delete(`/api/positions/${positionId}`, { json: input }).json();
  },

  logSseConnectionFailure: async (): Promise<Response> => httpClient.post('/api/event/sse_failure'),

  getCompanyEnfusionPriceDetails: async (companyId: string): Promise<CompanyEnfusionPriceDetails> => {
    const priceDetails = await httpClient
      .get(`/api/companies/${companyId}/enfusionPriceDetails`)
      .json<RawDateTimes<CompanyEnfusionPriceDetails>>();
    return {
      ...priceDetails,
      priceTime: parseDateTime(priceDetails.priceTime),
    };
  },

  getPortfolioEnfusionPriceDetails: async (): Promise<PortfolioEnfusionPriceDetails> => {
    const portfolioPriceDetails = await httpClient
      .get(`/api/positions/enfusionPriceDetails`)
      .json<RawDateTimes<PortfolioEnfusionPriceDetails>>();
    return {
      ...portfolioPriceDetails,
      pullTime: parseDateTime(portfolioPriceDetails.pullTime),
      portfolioCompanyPricingDetails: _(portfolioPriceDetails.portfolioCompanyPricingDetails)
        .map((companyPriceDetails) => ({
          ...companyPriceDetails,
          priceTime: parseDateTime(companyPriceDetails.priceTime),
        }))
        .value(),
    };
  },

  getPortfolios: async (): Promise<Portfolio[]> => {
    return await httpClient.get('/api/portfolio').json<Portfolio[]>();
  },

  addToEnfusionCoverage: async (companyId: string): Promise<void> => {
    await httpClient.put(`/api/companies/${companyId}/addToEnfusionCoverage`);
  },

  getBuysidePortofioAttribution: async (
    timeFrame: BuysidePortfolioTimeFrames,
  ): Promise<BuysidePortfolioAttribution> => {
    const pfl = await httpClient
      .get(`/api/portfolio/attribution/fixed-time?timeHorizon=${timeFrame}`)
      .json<RawDateTimes<BuysidePortfolioAttribution>>();
    return {
      ...pfl,
      startDate: parseDateTime(pfl.startDate),
      endDate: parseDateTime(pfl.endDate),
      ideaAttributions: pfl.ideaAttributions.map((a) => ({
        ...a,
        closeTime: a.closeTime ? parseDateTime(a.closeTime) : null,
      })),
    };
  },

  getAlerts: async (type: AlertType, entityId?: string): Promise<Alert[]> => {
    const alerts = await httpClient
      .get('/api/alerts', {
        searchParams: {
          type,
          ...(entityId && { entityId }),
        },
      })
      .json<RawDateTimes<Alert[]>>();
    return _(alerts)
      .map((alert) => ({
        ...alert,
        beginTime: parseDateTime(alert.beginTime),
        endTime: (alert.endTime && parseDateTime(alert.endTime)) || null,
      }))
      .value();
  },

  getLeague: async (timeHorizon: LeagueTimeHorizon, forDate?: DateTime): Promise<LeagueSnapshot> => {
    const snapshot = await httpClient
      .get('api/league', {
        searchParams: {
          timeTimeHorizon: timeHorizon,
          ...(forDate && { forDate: forDate.toISODate() }),
        },
      })
      .json<RawDateTimes<LeagueSnapshot>>();
    return {
      ...snapshot,
      generatedAt: parseDateTime(snapshot.generatedAt),
      forDate: parseDateTime(snapshot.forDate),
    };
  },

  recordUserActionAnalyticsMetric: async (
    page: AppPage,
    action: UserAction,
    browserTime: DateTime,
    metaData: Record<string, string>,
  ): Promise<void> => {
    await httpClient.get(`/api/analytics/userAction/${page}/${action}`, {
      searchParams: {
        ...metaData,
        browserTime: browserTime.toISO(),
      },
    });
  },
};

export default Api;
